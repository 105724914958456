<template>
  <ev-circle-loader
    v-if="loading"
    data-test="loader"
    variant="tertiary"
  />

  <div class="signup" v-else>
    <router-view data-test="router-view" />
  </div>
</template>

<script>
import EvCircleLoader from '@revelotech/everestV2/EvCircleLoader'

import { createNamespacedHelpers } from 'vuex'
import { CURRENT_USER } from '@/store/namespaces'

const currentUserHelper = createNamespacedHelpers(CURRENT_USER)

export default {
  name: 'Signup',

  components: { EvCircleLoader },

  data () {
    return {
      loading: true
    }
  },

  async created () {
    await this.getCurrentUser()

    this.loading = false
  },

  methods: {
    ...currentUserHelper.mapActions(['getCurrentUser'])
  }
}
</script>

<style lang="scss" scoped>
.signup {
  height: 100vh;
}
</style>
